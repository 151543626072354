.bookingDetailsContainer
{
    width: 550px;
    padding: 16px;
    background: var(--ui-background);
    border-radius: 8px;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    display: inline-flex;
}

.font-subheading
{
    font-size: 14px;
    font-weight: 400px;
}